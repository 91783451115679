export const Bio = {
  name: "Lena Sorken",
  roles: ["Front-End Developer", "Developer Student"],
  description:
    "Hello! My name is Lena and I'm currently studying Information Technology - Frontend and mobile development at Høyskolen Kristiania. I'm based in Oslo. I'm passionate about creating, learning and exploring to make my projects better. In my free time I like to play video games, read and make digital art.",
  github: "https://github.com/explicitlena",
  resume: "https://www.flipsnack.com/BDCCFC66AED/lena_sorken_cv/full-view.html",
  linkedin: "https://www.linkedin.com/in/lenasorken/",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Bootstrap",
        image:
          "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      }
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
    ],
  },
  {
    title: "Android",
    skills: [
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
      },
      {
        name: "Kotlin",
        image:
          "https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg",
      },
      {
        name: "Android Studio",
        image:
          "https://developer.android.com/static/studio/images/new-studio-logo-1_1920.png",
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "Netlify",
        image:
          "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Postman",
        image:
          "https://res.cloudinary.com/postman/image/upload/t_team_logo/v1629869194/team/2893aede23f01bfcbd2319326bc96a6ed0524eba759745ed6d73405a3a8b67a8",
      },
      {
        name: "Adobe XD",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/c/c2/Adobe_XD_CC_icon.svg",
      },
      {
        name: "Figma",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Figma-logo.svg/800px-Figma-logo.svg.png",
      },
    ],
  },
];

export const experiences = [
  {

  },
];  

export const education = [
  {
    id: 0,
    img: "https://karriere.kristiania.no/wp-content/uploads/2022/07/Kristiania_logo_-150x150.png",
    school: "Kristiania University, Oslo",
    date: "Aug 2022 - May 2025",
    desc: "I am currently pursuing a Bachelor's degree in IT - Frontend and mobile development in Oslo, Norway. I have completed 3 semesters, and I have taken courses in Web development, Android programming, Object-oriented programming, Interaction Design, Databases, Python and Unity Programming so far.",
    degree:
      "Bachelor in Information Technology - Frontend and Mobile Development",
  },
];

export const projects = [
  {
    id: 9,
    title: "Formula 1 Special Event Exam",
    date: "November 2023 - December 2023",
    description:
      "Formula 1 Special Event was my exam project in Web development for the 3rd semester in 2023.",
    image:
      "https://cdn.discordapp.com/attachments/676889194508845057/1175104491691970633/image.png?ex=659828d7&is=6585b3d7&hm=6ee52a55186dafdb19b6f7a50f18c95f6f78faf37b67591b97ddf4f242829450&",
    tags: [
      "HTML",
      "CSS",
      "JavaScript",
      "Bootstrap",
      "C#",
      "React Js",
      "ASP.NET Core",
    ],
    category: "web app",
    github: "#",
    webapp: "#",
  },
  {
    id: 0,
    title: "Hangman",
    date: "Apr 2023 - May 2023",
    description:
      "This Hangman game is a practicing project i built using Vanilla JavaScript and can be played by using your keyboard. You suggest letters one at a time to find the hidden word. It also includes a hint button to provide players with a clue, and a 'New Word' button to randomize a new word to guess",
    image:
      "https://camo.githubusercontent.com/37a6cb1a92b3777baf1476e159f1b44208460476b921b38c1a19134798ea184e/68747470733a2f2f6a2e676966732e636f6d2f33366731476e2e676966",
    tags: ["HTML", "CSS", "Vanilla JavaScript"],
    category: "web app",
    github: "https://github.com/explicitlena/hangman",
    webapp: "https://explicitlena-hangman.netlify.app/",
  },
  {
    id: 1,
    title: "Super Mario",
    date: "Oct 2022 - Present",
    description: "My first HTML and CSS project in the second semester",
    image:
      "https://cdn.discordapp.com/attachments/676889194508845057/1190271043596734474/image.png?ex=65a1314a&is=658ebc4a&hm=38966d6f3bfd922687f15f04f1b8b31dc98b376db4662ba661998f87fa394922&",
    tags: ["HTML", "CSS"],
    category: "web app",
    github: "https://github.com/explicitlena/super-mario",
    webapp: "https://mario-arbeidskrav.netlify.app/",
  },
];

